import { bscTestnet, bsc } from "viem/chains";

export const environment = {
    production: false,
    SUPPORTED_CHAINS: [bscTestnet],
    ENCRYPT_LOCAL_STORAGE: false,
    LANGUAGES_FILE_PATH: 'assets/i18n/',
    LOCAL_STORAGE_SECRET: 'D96Q2M84E3400063E8366912AQ45488H',
    WALLET_CONNECT_PROJECT_ID: '00507133e3e070843a1beab55a1ebfe2',
    dev_url:'https://web3.alphaomegacoin.com',
    COINBIT_API_BASE_URL: 'https://api.coinsbit.io/api/v1',
    NETWORK_ID:['0x61'],
    COUNTRY_CODE_BASE_URL: 'https://ipapi.co/json/',
    API_BASE_URL: 'https://aoctokendistapi.seaswap.co/api/v1',
    CHAIN_ID: 97,
    NETWORK_NAME:'BSC-TESTNET',
    NETWORK_SYMBOL:'bsc',
    NETWORK_CURRENCY:'BNB',
    TRANSACTION_URI: 'https://testnet.bscscan.com/tx/',
    TEST_CHAIN: 'bsc',
    RPC_URL: "https://bsc-testnet.blockpi.network/v1/rpc/public",
    AOC_TOKEN : "0x8BE3f52CA83E7C2aEf7C33dcE7fAB0106CDfa829",
    CROWD_SALE:"0x7de3630d8C04Fc425a07CAe81080923d60cDA2d8",
    VESTING_CONTRACT:"0x19F36bBe410C6BC2c263146073F9396F2e5ac091",
    USDT_CONTRACT:"0x52D896ff34D797efe460dB898DDa1edE0bfe4a9b",
    CROWD_SALE_V2:"0xEd1CbD0a9d2bE3CE86d466DdccDCE19E6AF2A97E",
    DISTRIBUTION_START_DATE: "Feb 2025",
    WEB3_APP_URL:'https://aoc-user.seaswap.co/',
    EN_AUDIT_LINK:"https://drive.google.com/file/d/1yXaQjWzjK0bQZWU7Gvt32y1DQIJKAZpO/view",
    FR_AUDIT_LINK:"https://drive.google.com/file/d/19PqMLKG5pkqBgaOvo4ZqDfiLoJ9B2NMy/view",

};
